/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');

a {
  cursor: pointer;
  font-family: Ubuntu !important;
}

span {
  font-family: Ubuntu !important;
}

td {
  font-family: Ubuntu !important;
}

div {
  font-family: Ubuntu !important;
}

mat-label {
  font-family: Ubuntu !important;
}

label {
  font-family: Ubuntu !important;
}

/* .mat-mdc-simple-snack-bar{
  background-color: #ff3333;
} */

body {
  overflow: auto;
  font-family: Helvetica,Arial,sans-serif !important;
}

.ui.menu {
  font-family: Helvetica,Arial,sans-serif !important;
}

/**controles kobra**/
.kobra-icon{
  color: #00C776 !important;
  /*margin-right: 0px !important;*/
}

.kobra-delete-icon{
  color: red !important;
}

.kobra-button{
  background-color: #00c977 !important;
  color: white !important;
  /* margin-left: 20px; */
}

.kobra-button-cat{
  background-color: white;
}

.kobra-button-right{
  margin-left: 10px;
}

/* custom table */
.table-container {
  position: relative;
  display: flex;
  flex-direction: column;
  /*height: calc(100vh - 170px);*/
  margin-top: 10px;
  /*padding: 10px;*/
  background-color: white;
}

.sub-tabla{
  margin-top: -90px;
  max-height: 245px;
}

.table-header {
  flex: 0 1 auto;
  padding: 8px 16px;
}

.table-wrapper {
  overflow: auto;
  flex: auto;
}

.paginator {
  flex: 0 1 auto;
  padding: 0 10px 10px 0px;
}

.selectedRow {
  background-color: #E0F7FA !important;
}
/****************/


/*********************/
/*    App Elments    */
/*********************/
.app.loader {
  position: fixed;
  z-index: 1002;
}

.app.splash {
  display: table;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
}
.app.splash > .content {
  display: table-cell;
  overflow: hidden;
  vertical-align: middle;
}

/*****************/
/*    Classes    */
/*****************/
.cursor.pointer, .cursor.pointer * { cursor: pointer; }
.float.right { display: block; float: right; }
.margin.auto { margin-left: auto; margin-right: auto; }
.max { width: 100%; }
.max.w200 { max-width: 200px; }
.max.w300 { max-width: 300px; }
.max.w400 { max-width: 400px; }
.max.w500 { max-width: 500px; }
.max.w600 { max-width: 600px; }
.no.margin { margin: 0 !important; }
.no.padding { padding: 0 !important; }
.table { display: table; }
.table .cell { display: table-cell; vertical-align: top; }
.table .cell.middle { vertical-align: middle; }
.align.vertical { display: table !important; height: 100%; }
.align.vertical .middle { display: table-cell; vertical-align: middle; }
.w100 { width: 100%; }

/*********************/
/*    Semantic UI    */
/*********************/
.ui.action.input .ui.dropdown {
  border-radius: 5px 0 0 5px;
}

.ui.header small {
  color: #888;
}

.ui.dimmer {
  z-index: 1004;
}

.ui.dimmer.modals {
  z-index: 1004;
}

.ui.card.add,
.ui.segment.add {
  border: 2px dashed #aaa;
  color: #777;
  cursor: pointer;
}

.ui.card.add:hover,
.ui.segment.add:hover {
  border: 2px dashed #777;
  color: black;
}

.ui.table tbody tr.selected {
  background-color: #ffc !important;
}

.ui.table tbody tr.selected:hover {
  background-color: #ffa !important;
}

/* REFACTOR HTML LAYOUT **********************************/

/****************/
/*    HEADER    */
/****************/

.notification-dot {
  position: absolute;
  top: -3px; /* Ajusta según sea necesario */
  right: -3px; /* Ajusta según sea necesario */
  width: 15px; /* Aumenta el tamaño para acomodar el número */
  height: 15px; /* Aumenta el tamaño para acomodar el número */
  border-radius: 50%;
  background-color: red;
  color: white; /* Color del texto */
  font-size: 10px; /* Tamaño del texto */
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-dot-tamanio-chico{
  position: absolute;
  top: 0;
  margin-left: 15px;
  width: 15px; /* Aumenta el tamaño para acomodar el número */
  height: 15px; /* Aumenta el tamaño para acomodar el número */
  border-radius: 50%;
  background-color: red;
  color: white; /* Color del texto */
  font-size: 10px; /* Tamaño del texto */
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-dot-menu{
  position: absolute;
  top: 5px;
  margin-left: -25px;
  width: 15px; /* Aumenta el tamaño para acomodar el número */
  height: 15px; /* Aumenta el tamaño para acomodar el número */
  border-radius: 50%;
  background-color: red;
  color: white; /* Color del texto */
  font-size: 10px; /* Tamaño del texto */
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-dot-filtros {
  margin-left: 5px;
  width: 15px; /* Aumenta el tamaño para acomodar el número */
  height: 15px; /* Aumenta el tamaño para acomodar el número */
  border-radius: 50%;
  background-color: #673ab7;
  color: white; /* Color del texto */
  font-size: 10px; /* Tamaño del texto */
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-dot-accion{
  position: absolute;
  top: 3px; /* Ajusta según sea necesario */
  right: 3px; /* Ajusta según sea necesario */
}

.snackbar-success {
  background-color: #00c977 !important; /* Verde para éxito */
  color: white;
}

.snackbar-error {
  background-color: #ff3333 !important; /* Rojo para error */
  color: white;
}

.snackbar-alert {
  background-color: #fff670 !important; /* Ámbar para alertas */
  color: white;
}
.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: transparent !important; /* Establece un fondo transparente */

}

.app.header {
  background-color: #041a22 !important;
  background: #041a22 !important;
  border-bottom: 1px solid #e7e7e7;
  height: 100px;
  left: 0;
  position: fixed;
  padding: 0 10px;
  top: 0;
  width: 100%;
  z-index: 1000;
  color: white;
}

.header.toolbar {
  /*position: fixed;*/
  left: 295px;
  top: -5px;
  margin-top: -15px;
  background-color: #f0eff3 !important;
  background: #f0eff3 !important;
  height: 80px;
  width: 100%;
}

.app.header .logo, .app.footer .logo {
  height: 100%;
  padding: 10px !important;
}

.app.header .logo img, .app.footer .logo img {
  height: 100%;
  border-radius: 2px;
}

.app.header .ui.menu {
  height: 100%;
}

.app.header .item.menu {
  display: none;
}

.app.header .dash-admin-text {
  font-family: Helvetica,Arial,sans-serif !important;
  color: #01c976 !important;
  font-size: 24px !important;

}

/**************/
/*    MENU    */
/**************/

.app.menu {
  background-color: #f7f7f7;
  background: #f7f7f7;
  /* background-color: #041a22;
  background: #041a22; */
  border-right: 1px solid #e7e7e7;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 80px;
  width: 220px;
  z-index: 999;
}

.app.menu.withfooter {
  bottom: 46px !important;
}

.app.menu .ui.menu {
  background-color: inherit !important;
  border: none;
  border-radius: 0 !important;
  width: 100% !important;
  box-shadow: none;
  margin: 0;
}

.app.menu .hide.menu {
  /*background-color: #f0f0f0;*/
  /* border-top: 1px solid #e0e0e0; */
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 15px;
}

/* .app.menu .header-menu-childs span {
  color: #01c976 !important;
}

.app.menu .header-menu-no-childs span {
  color: #01c976 !important;
}*/

 .app.menu .header-menu-childs.active, .app.menu .header-menu-no-childs.active, .app.menu .item-menu-children.active {
  color: #01c976 !important;
  font-weight: bold !important;
}

/* .app.menu .item-menu-children {
  color: #ffffff !important;
} */

/************************/
/*    MAIN CONTAINER    */
/************************/

.app.main {
  padding: 80px 0 0 220px;
  position: relative;
  height: 100vh;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.app.main > * {
  font-family: Helvetica,Arial,sans-serif !important;
  height: calc(100% - 80px);
}

.app.menu > * {
  font-family: Helvetica,Arial,sans-serif !important;
}


/*****************/
/*    TOOLBAR    */
/*****************/
.app.toolbar {
  background-color: #f3f3f3;
  background: #f3f3f3;
  border-bottom: 1px solid #e3e3e3;
  height: 80px;
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.app.toolbar .title, .app.toolbar .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.app.toolbar .actions > *:not(:last-child) {
  margin: 0px 8px 0px 8px;
}

.app.toolbar input {
  font-size: 12px !important;
  padding-bottom: 7px;
  padding-top: 6px;
}

/*********************/
/*    APP CONTENT    */
/*********************/

.app.content {
  padding: 0 10px 10px 10px;
  height: calc(100vh - 110px);
  overflow: auto;
  background-color: #f0eff3;
}

.app.content.tabla {
  height: inherit;
  overflow: hidden;
}

.app.content.fixed {
  position: absolute;
  top: 164px;
  left: 220px;
  right: 0;
  bottom: 0;
  overflow: auto;
  height: calc(60vh);

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}


.app.content.kobra.conayuda{
  height: calc(100% - 180px);
}

@media only screen and (max-width: 767px){
  .app.content.kobra.conayuda{
    height: calc(100% - 260px);
  }
}
/*********************/
/*    APP MESSAGE    */
/*********************/

.app.message {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1003;
}

.app.message .ui.message {
  border-radius: 0;
  min-height: 50px;
}

.app.message .ui.error.message {
  background-color: #ff3333;
  border-color: #ff3333;
  color: white;
}
.app.message .ui.success.message {
  background-color: #66ff99;
  border-color: #66ff99;
  color: black;
}

.mat-mdc-snack-bar-container.success{
  margin-top: 35px;
}
.success .mdc-snackbar__surface{
  background-color: #00c977 !important;
  border-color: #00c977;
  color: black !important;
}

.mat-mdc-snack-bar-container.warning{
  margin-top: 35px;
}
.mat-mdc-snack-bar-container.warning .mdc-snackbar__label{
  color: black !important;
}
.mat-mdc-snack-bar-container.warning .mat-mdc-button.mat-mdc-snack-bar-action{
  color: black !important;
}
.warning .mdc-snackbar__surface{
  background-color: #FFF670 !important;
  border-color: #FFF670;
  color: black !important;
}

.mat-mdc-snack-bar-container.error{
  margin-top: 35px;
  max-width: 1200px !important;
}
.error .mdc-snackbar__surface{
  background-color: #ff3333 !important;
  border-color: #ff3333;
  color: white;
  max-width: 1200px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.error .mdc-snackbar__surface:hover {
  overflow: visible;
  white-space: normal;
  text-overflow: clip;
}

.custom-menu {
  width: 750px;
  max-width: none !important;
  max-height: 600px !important;
  /* background-color: #232D3F;
  color: white; */
}

/*********************/
/*    APP OPTIONS    */
/*********************/
.app.options {
  background-color: #f7f7f7;
  border-left: 1px solid #e7e7e7;
  bottom: 0;
  display: none;
  position: fixed;
  right: 0;
  top: 80px;
  width: 250px;
  z-index: 999;
}
.app.options .header {
  background-color: #f0f0f0;
  background: #f0f0f0;
  border-bottom: 1px solid #e0e0e0;
  font-weight: bold;
  padding: 10px 10px;
  width: 100%;
}
.app.options .content {
  bottom: 0;
  position: absolute;
  top: 40px;
  width: 100%;
  overflow: auto;
}
.app.options .content .ui.menu {
  width: 100%;
  border: none;
  background-color: inherit;
  border-radius: 0;
}

/*********************/
/*    APP FOOTER    */
/*********************/
.app.footer{
  border-bottom: 1px solid #e7e7e7;
  position: fixed;
  bottom: -5px;
  width: 219px;
  z-index: 1000;
}

.app.footer, .app.footer .mat-toolbar {
  background-color: #041a22 !important;
  background: #041a22 !important;
}

/*********************/
/*    FULL SCREEN    */
/*********************/

.app.full.screen .app.main {
  padding-left: 0;
}

.app.full.screen .app.fixed.content {
  left: 0;
}

.app.full.screen .app.header .item.menu {
  display: block;
}

/****************/
/*    COLORS    */
/****************/
.ui.kobra {
  background-color: #00c977;
  color: white;
}

.ui.kobra a {
  color: white;
}

.ui.kobra.button:hover,
.ui.kobra.button:active,
.ui.kobra.button:focus {
  background-color: #00b56b;
  color: white;
}

sui-modal  {
  position: relative;
  z-index: 1003;
}

.newline {
  white-space: pre-wrap;
}

.contenidoConScroll {
  overflow: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  cursor: pointer;
}

::-webkit-scrollbar:vertical {
  width:5px;
}

::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}

*:hover::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #bbb4b4;
  border-radius: 1px;
  /*border: 2px solid #f1f2f3;*/
}

::-webkit-scrollbar-track {
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa5a5;
  cursor: pointer;
}

/****barritas de cargando en footer*****/
mat-card-footer i{
  margin-left:20px;
  color: #2196f3;
}
/****barritas de cargando en footer*****/


/***titulos****/
.kobra-titulo-opcion{
  margin-top: 15px;
  margin-left: 5px;
}

.kobra-nommbre-titulo{
  margin-top: 10px;
}

.kobra-titulo{
  font-size: x-large;
}
.kobra-subtitulo{
  font-weight: bolder;
}

.kobra-subtitulo-bajo{
  font-size: large;
  color: gray;
}

.kobra-lado-titulo{
  padding-left: 10px;
  color: gray;
}

/****titulos****/

.cardAyuda {
  margin-top: 30px;
  background: aliceblue !important;
  margin-left: 20px;
  margin-right: 20px;
}

.cardAyuda p {
  margin: 12px 12px 12px;
}

.mat-card.kobra-card{
  border: 1px solid #00000045 !important;
}

/*.mat-mdc-card-title{
  margin: 15px !important;
}*/

.kobra-card-footer{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: unset !important;
}

.kobra-footer-catalogo{
  position: relative;
  bottom: 15px;
  left: 0;
  width: 100%;
  margin: unset !important;
}

.kobra-card-content{
  margin-top: 20px;
}

.kobra-card-buttons-actions mat-card-actions{
  position: absolute !important;
  right: 15px;
  bottom: -5px;
}

.kobra-card-buttons-actions mat-divider{
  position: absolute !important;
  bottom: 65px;
}

.kobra-forms-buttons{
  margin-bottom: 10px;
}

/****KOBRA COLUMNAS****/

.kobra.two.columns, .kobra.three.columns, .kobra.four.columns{
  display: flex;
  flex-wrap: wrap;
}

.kobra.column{
  padding: 0 1em;
  box-sizing: border-box;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-top: 5px;
  display: grid;
}

.kobra.two.columns > .kobra.column{
  width: 50%;
  max-width: 50%;
}

.kobra.two.columns > .kobra.column.two-colspan{
  width: 100%;
  max-width: 100%;
}

.kobra.three.columns > .kobra.column{
  width: 33.333%;
}

.kobra.three.columns > .kobra.column.two-colspan{
  width: 66.666%;
  max-width: 66.666%;
}

.kobra.three.columns > .kobra.column.three-colspan{
  width: 100%;
  max-width: 100%;
}

.kobra.four.columns > .kobra.column{
  width: 25%;
  max-width: 25%;
}

.kobra.four.columns > .kobra.column.two-colspan{
  width: 50%;
  max-width: 50%;
}

.kobra.four.columns > .kobra.column.three-colspan{
  width: 75%;
  max-width: 75%;
}

.kobra.four.columns > .kobra.column.four-colspan{
  width: 100%;
  max-width: 100%;
}

.kobra.column label{
  font-weight: bold;
}

.mat-mdc-form-field label{
  font-weight: 500 !important;
}

@media only screen and (max-width: 767px){
  .kobra.two.columns .kobra.column{
    width: 100%;
  }

  .kobra.three.columns .kobra.column.two-colspan{
    width: 100%;
  }

  .kobra.three.columns .kobra.column.three-colspan{
    width: 100%;
  }

  .kobra.three.columns .kobra.column{
    width: 100%;
  }

  .kobra.four.columns .kobra.column{
    width: 100%;
  }

  .kobra.four.columns .kobra.column.two-colspan{
    width: 100%;
  }

  .kobra.four.columns .kobra.column.three-colspan{
    width: 100%;
  }

  .kobra.four.columns .kobra.column.four-colspan{
    width: 100%;
  }
}

/****KOBRA COLUMNAS****/
.mat-icon-kobra{
  color: #00C776;
  margin-right: 3px;
}

.cdk-overlay-pane {
  z-index: 1004 !important; /* Ajusta el valor según tus necesidades */
}

/****KOBRA FILTROS***/
.sidenav-right{
  width: 500px;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(244 67 54 / 40%);
  z-index: 101 !important;
  margin-left: -7px;
}

.filter-button-side {
  position: absolute !important;
  top: 10px;
  right: 10px;
  background-color: #f5f5f5 !important;
  color: #000;
  /* Agrega otros estilos si lo deseas */
}

.filter-button-side mat-icon{
  margin-left: 10px;
  margin-top: 5px;
}

.accordion-filtros{
  max-height: calc(100% - 185px);
  overflow-y: auto;
}

.accordion-filtros .lista-filtros-side {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-left: -1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: white;
  border-radius: 0.25rem;
  max-height: inherit;
  width: 100%;
  margin-top: 0px;
}

.lista-filtros-side {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: white;
  border-radius: 0.25rem;
  max-height: calc(100% - 144px);
  margin-top: 0px;
}

.lista-filtros-buttons {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: white;
  border-radius: 0.25rem;
  max-height: 80px;
  margin-top: 0px;
}

.lista-filtros-groupless{
  margin-top: -50px !important;
}

.filtro-side{
  width: 100%;
}

.div-filtros-side{
  width: 100%;
  overflow: auto;
}

.limpiar-seleccion-kobra{
  color: #673ab7;
  margin-top: -15px;
  position: relative;
  margin-bottom: 5px;
  z-index: 1000; /* Puedes ajustar el valor según sea necesario */
  /* Agrega otras propiedades de estilo según sea necesario */
}

.list-actions-kobra {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.list-actions-kobra button,
.list-actions-kobra a {
  margin-right: 8px;
}

.list-actions-kobra button {
  flex: 1 1 auto;
  min-width: 120px;
}

.input-catalogo{
  left: 0 !important;
  margin-left: 20px !important;
  width: 100% !important;
}

/**COMPONENTES DE MATERIAL PARA CAMBIARLES LOS COLORES***/
.mdc-list-item__primary-text {
  font-family: Ubuntu !important;
}

.mat-expansion-panel-header {
  font-family: Ubuntu !important;
}

.kobra-radio {
  --mdc-radio-selected-focus-icon-color: var(--lila-kobra-hover) !important;
  --mdc-radio-selected-hover-icon-color: var(--lila-kobra-hover) !important;
  --mdc-radio-selected-icon-color: var(--lila-kobra) !important;
  --mdc-radio-selected-pressed-icon-color: var(--lila-kobra) !important;
  --mat-mdc-radio-checked-ripple-color: var(--lila-kobra) !important;
}

:root{
  --verde-kobra: #00c977;
  --verde-kobra-hover: #00c980;
  --verde-kobra-claro: #35f0ab;
  --verde-toolbar: #008170;
  --azul-kobra: #041a22;
  --azul-menu: #232D3F;
  --lila-kobra: #673ab7;
  --lila-kobra-claro: #764ebb;
  --lila-kobra-hover: #602bbb;
}

.kobra-checkbox {
  --mdc-checkbox-selected-checkmark-color: white !important;
  --mdc-checkbox-selected-focus-icon-color: var(--lila-kobra) !important;
  --mdc-checkbox-selected-hover-icon-color: var(--lila-kobra-hover) !important;
  --mdc-checkbox-selected-icon-color: var(--lila-kobra) !important;
  --mdc-checkbox-selected-pressed-icon-color: var(--lila-kobra) !important;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}

.kobra-checkbox .mdc-checkbox__ripple{
  background: var(--lila-kobra-claro) !important;
}

.kobra-slide-toggle{
  --mdc-switch-selected-focus-state-layer-color: var(--lila-kobra) !important;
    --mdc-switch-selected-handle-color: var(--lila-kobra) !important;
    --mdc-switch-selected-hover-state-layer-color: var(--lila-kobra) !important;
    --mdc-switch-selected-pressed-state-layer-color: var(--lila-kobra) !important;
    --mdc-switch-selected-focus-handle-color: var(--lila-kobra-hover) !important;
    --mdc-switch-selected-hover-handle-color: var(--lila-kobra-hover) !important;
    --mdc-switch-selected-pressed-handle-color: var(--lila-kobra-hover) !important;
    --mdc-switch-selected-focus-track-color: var(--lila-kobra-claro) !important;
    --mdc-switch-selected-hover-track-color: var(--lila-kobra-claro) !important;
    --mdc-switch-selected-pressed-track-color: var(--lila-kobra-claro) !important;
    --mdc-switch-selected-track-color: var(--lila-kobra-claro) !important;
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.boton-guardar{
  background-color: #00c977 !important;
  color:white !important;
  cursor: pointer;
}

.boton-accion-front {
  background-color:  #673ab7 !important;
  color:white !important;
  cursor: pointer;
}

.buton-disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  pointer-events: none;
}


.button-toggle-group-container {
  display: flex !important;
  justify-content: right !important;
  margin-right: 20px !important;
  pointer-events: auto;
  /*background-color: white;*/
}

.button-toggle-group-container-card {
  display: flex !important;
  justify-content: right !important;
  pointer-events: auto;
  margin-left: auto;
  margin-right: 10px;
}

.button-toggle-group-container.right{
  margin-left: auto;
}

.kobra-icon-ayuda {
  margin-left: 5px;
  margin-right: 5px;
}

.filter-item {
  display: flex;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

/*.column {
  flex: 1 0 30%;
  margin: 8px;
  min-width: 200px;
}*/

.botones-card-derecha {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap:10px;
}

.button-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -133px;
  margin-top: 10px;
  margin-left: 10px;
  z-index: 1;
  pointer-events: none;
}

@media (max-width: 767px) {
  .button-container-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .button-toggle-group-container {
    order: 1;
    margin-top: 10px;
  }
}

mat-card-header{
  margin-bottom: 20px;
}

.encabezado-modal {
  padding: 0 0px 9px !important;
}

.boton-cancelar {
  background-color: #e0e0e0 !important;
  color: #333 !important;
}

.boton-accion-eliminar {
  background-color:  #f44336 !important;
  color:white !important;
  cursor: pointer;
}

.boton-accion-guardar {
  background-color: #00c977 !important;
  color:white !important;
  cursor: pointer;
}

mat-button-toggle-group{
  background-color: white !important;
}

.mat-button-toggle-button{
  background: white !important;
}

a {
  color: #673ab7 !important;
}

.sombreado-link{
  transition: background-color 0.3s ease;
  padding: 10px;
  border-radius: 5px;
}

.sombreado-link:hover{
  background-color: #673ab71a;
}

.boton-accion-front {
  background-color:  #673ab7 !important;
  color:white !important;
  cursor: pointer;
}

.boton-accion-eliminar {
  background-color:  #f44336 !important;
  color:white !important;
  cursor: pointer;
}

.boton-accion-guardar {
  background-color: #00c977 !important;
  color:white !important;
  cursor: pointer;
}

.icono-limpiar{
  padding:12px 0px !important;
  margin-right: -7px;
  cursor: pointer;
  color: #757575;

}

.icono-limpiar-solo{
  padding:12px 5px  !important;
  cursor: pointer;
  color: #757575;

}

.icono-flotante-hora {
    position: fixed;
    bottom: 4px;
    right: 45px;
    z-index: 1000;
    font-size: 24px;
    color: #757575;
    cursor: pointer;
}

.sin-info{
  text-align: center;
  padding: 20px !important;
  background-color: white;
}

.green-line {
  border-top: 5px solid #00c977 !important;
}

.fec-registro{
  color: rgba(0, 0, 0, .54);
  font-size: 14px;
  font-weight: 500;
}

.fec-registro.small{
  font-size: 12px;
}


.readonly-input[readonly] {
  cursor: not-allowed; /* Cursor que indica que no es editable */
}

.dotted-separator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0; /* Margen superior e inferior */
  font-size: 20px; /* Tamaño de los puntos */
  letter-spacing: 5px; /* Espaciado entre puntos */
  color: #00C776; /* Color de los puntos */
  font-weight: bold;
}

.dotted-separator::before {
  content: "• • •"; /* Usa el número de puntos definido en el atributo */
  display: block;
  font-size: inherit;
  color: inherit;
}


mat-card-actions{
  gap:10px !important;
}
